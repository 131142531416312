<template>
  <div id="app">
    <mainrouterpages></mainrouterpages>
  </div>
</template>

<script>
import mainrouterpages from"@/components/views/mainrouterpages.vue"
export default {
  name: 'App',
  components: {
    mainrouterpages
  }
}
</script>

<style>
@import url("./assets/font/font/iconfont.css");
</style>
