import { render, staticRenderFns } from "./playBar.vue?vue&type=template&id=8f5177ca&scoped=true&"
import script from "./playBar.vue?vue&type=script&lang=js&"
export * from "./playBar.vue?vue&type=script&lang=js&"
import style0 from "./playBar.vue?vue&type=style&index=0&id=8f5177ca&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8f5177ca",
  null
  
)

export default component.exports