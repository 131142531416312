import { render, staticRenderFns } from "./recommend-songs.vue?vue&type=template&id=612f93b8&scoped=true&"
import script from "./recommend-songs.vue?vue&type=script&lang=js&"
export * from "./recommend-songs.vue?vue&type=script&lang=js&"
import style0 from "./recommend-songs.vue?vue&type=style&index=0&id=612f93b8&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "612f93b8",
  null
  
)

export default component.exports