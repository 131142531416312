<template>
  <div class="search-songs">
    <play-list></play-list>
  </div>
</template>

<script>
import playList from "../playMU/playList.vue"
export default {
name:"SearchSongs",
components:{
    playList
}
}
</script>

<style>

</style>